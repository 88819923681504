<script>
import { uploadFile } from "@/api/upload";
import { ElLoading, ElMessage, ElMessageBox } from "element-plus"; // Import ElMessageBox
export default {
  name: "FileUploadSettings",
  data() {
    return {
      isVisible: true,
      loadingInstance: null,
    };
  },
  methods: {
    async confirmUpload(params) {
      try {
        await ElMessageBox.confirm(
          "Подтвердите, что вы хотите загрузить файл",
          "Подтверждение",
          {
            confirmButtonText: "Загрузить",
            cancelButtonText: "Отмена",
            type: "warning",
          }
        );
        await this.upload(params);
      } catch {
        ElMessage({
          type: "info",
          message: "Вы отменили загрузку.",
        });
      }
    },
    async upload(params) {
      const formData = new FormData();
      formData.append("file", params.file);
      formData.append("type", "file");
      this.loadingInstance = ElLoading.service({
        target: ".el-drawer",
        text: "Загружается файл...",
      });

      try {
        const data = await uploadFile(formData);
        console.log(data);
      } catch (error) {
        console.log(error);
      } finally {
        if (this.loadingInstance) {
          this.loadingInstance.close();
        }
      }
    },
    close() {
      this.$router.push({ name: "settings" });
    },
  },
};
</script>

<template>
  <el-drawer
    size="600"
    title="Загрузить файл"
    v-model="isVisible"
    @close="close"
  >
    <div class="h-full overflow-y-auto flex flex-col gap-y-2.5">
      <div>
        <el-upload
          drag
          :data="{ type: 'file' }"
          :show-file-list="true"
          :http-request="confirmUpload"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            <em>Нажмите, чтобы загрузить</em>
          </div>
        </el-upload>
      </div>
    </div>
  </el-drawer>
</template>

<style scoped lang="scss"></style>
