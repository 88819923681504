<template>
  <div class="text-sm h-full">
    <header
      style="height: 60px"
      class="grid w-full grid-cols-12 text-sm border-b"
    >
      <div
        class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2 pl-4 pt-2.5"
      >
        <TheLogo />
      </div>

      <div
        class="flex items-center h-full col-span-6 md:col-span-8 lg:col-span-9 xl:col-span-10"
      >
        <nav
          class="ml-6"
          :class="
            user.scopes.includes('annotator') ||
            user.scopes.includes('verifier')
              ? ''
              : 'mr-6'
          "
        >
          <ul class="flex text-sm font-light">
            <li v-for="(l, index) in navLinks" :key="index">
              <router-link class="inline-block text-gray-400" :to="l.link">
                {{ l.title }}
              </router-link>
            </li>
          </ul>
        </nav>
        <a
          :href="shortLink"
          target="_blank"
          class="cursor-pointer text-blue-700 underline"
        >
          Правила разметки
        </a>
        <div class="ml-auto mr-4">
          <el-dropdown @command="handleCommand" trigger="click">
            <span class="el-dropdown-link">
              <div class="flex items-center text-gray pointer">
                <el-avatar class="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18px"
                    height="20px"
                    viewBox="0 0 24 24"
                    fill="white"
                  >
                    <circle
                      cx="12"
                      cy="6"
                      r="4"
                      stroke="#fff"
                      stroke-width="1.5"
                    />
                    <path
                      d="M20 17.5C20 19.9853 20 22 12 22C4 22 4 19.9853 4 17.5C4 15.0147 7.58172 13 12 13C16.4183 13 20 15.0147 20 17.5Z"
                      stroke="#fff"
                      stroke-width="1.5"
                    />
                  </svg>
                </el-avatar>
                <div>
                  {{ userName }} <br />
                  <span class="text-xs text-gray-400 mt-2 font-light">{{
                    userRole
                  }}</span>
                </div>

                <i class="ml-1 el-icon-arrow-down"></i>
              </div>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="forgot_password"
                  >Забыли пароль ?</el-dropdown-item
                >
                <el-dropdown-item command="logout">Выход</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </header>
    <router-view class="h-full" />
  </div>
</template>

<script>
import TheLogo from "@/components/header/TheLogo";
import LocalStorageService from "@/services/LocalStorageService";
import { forgotPassword } from "@/api/users";
const localStorageService = LocalStorageService.getService();

export default {
  name: "default",
  components: {
    TheLogo,
  },
  data() {
    return {
      shortLink: "https://u.to/SGsFIQ",
    };
  },

  computed: {
    user() {
      return this.$store.state.user.user;
    },
    userName() {
      return `${this.user.first_name} ${this.user.last_name}`;
    },
    userRole() {
      return this.user.scopes.join(", ");
    },
    navLinks() {
      let links = [];
      if (this.user && this.user.scopes.includes("admin")) {
        links.push({
          title: "Файлы",
          link: { name: "files" },
        });
      }
      if (this.user && this.user.scopes.includes("admin")) {
        links.push({
          title: "Управление",
          link: { name: "manage" },
        });
        links.push({
          title: "Сводка",
          link: { name: "summary" },
        });
      }
      return links;
    },
  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case "logout":
          localStorageService.clearToken();
          window.location.reload();
          break;
        case "forgot_password":
          this.userForgotPassword();
          break;
      }
    },
    async userForgotPassword() {
      this.$confirm(
        "На вашу почту будет отправлено сообщение с инструкциями как сбросить пароль",
        "Внимание",
        {
          confirmButtonText: "Да",
          cancelButtonText: "Нет",
        }
      ).then(async () => {
        let data = await forgotPassword(this.user._id);
        console.log(data);
      });
      // let data =
    },
  },
};
</script>

<style lang="scss">
nav ul li {
  margin-right: 30px;
  &:last-child {
    margin-right: 0;
  }
  a.router-link-active {
    color: #446bea;
    font-weight: normal;
  }
}
</style>
