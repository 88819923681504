<template>
  <div class="">
    <div class="flex flex-col gap-y-2.5">
      <div
        class="w-full border bg-white cursor-pointer relative"
        ref="wf"
        v-loading="mapLoading"
      >
        <div id="waveformFragment"></div>
        <canvas
          id="waveformCanvas"
          class="bg-white absolute top-0 left-0 w-full h-full"
        ></canvas>
      </div>

      <div class="flex flex-col items-center justify-center w-full">
        <div class="text-lg mt-5">
          Фрагмент # {{ audioStart }}/{{ lines.length }}
        </div>
        <div class="w-full mt-3" v-loading="fragmentLoading">
          <div id="waveform"></div>
        </div>
      </div>

      <div class="flex items-center gap-x-3 w-full justify-center mt-5">
        <div class="button rounded text-lg font-light w-24">
          {{ timeNormalizer(cue) }}
        </div>
        <div class="flex items-center gap-x-3">
          <el-tooltip content="Предыдущий фрагмент (Alt + ←)" :show-after="300">
            <div class="button" @click="previousFragment">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style="height: 20px; width: 20px"
                viewBox="0 0 24 24"
                fill="transparent"
              >
                <path
                  d="M15 5L9 12L15 19"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </el-tooltip>
          <el-tooltip
            content="Воспроизводить и приостанавливать (Escape)"
            :show-after="300"
          >
            <div class="button" @click="playPause">
              <div v-if="isPlaying">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="height: 20px; width: 20px"
                  viewBox="0 0 24 24"
                  fill="transparent"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="1.5"
                  />
                  <path
                    d="M8 9.5C8 9.03406 8 8.80109 8.07612 8.61732C8.17761 8.37229 8.37229 8.17761 8.61732 8.07612C8.80109 8 9.03406 8 9.5 8C9.96594 8 10.1989 8 10.3827 8.07612C10.6277 8.17761 10.8224 8.37229 10.9239 8.61732C11 8.80109 11 9.03406 11 9.5V14.5C11 14.9659 11 15.1989 10.9239 15.3827C10.8224 15.6277 10.6277 15.8224 10.3827 15.9239C10.1989 16 9.96594 16 9.5 16C9.03406 16 8.80109 16 8.61732 15.9239C8.37229 15.8224 8.17761 15.6277 8.07612 15.3827C8 15.1989 8 14.9659 8 14.5V9.5Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                  />
                  <path
                    d="M13 9.5C13 9.03406 13 8.80109 13.0761 8.61732C13.1776 8.37229 13.3723 8.17761 13.6173 8.07612C13.8011 8 14.0341 8 14.5 8C14.9659 8 15.1989 8 15.3827 8.07612C15.6277 8.17761 15.8224 8.37229 15.9239 8.61732C16 8.80109 16 9.03406 16 9.5V14.5C16 14.9659 16 15.1989 15.9239 15.3827C15.8224 15.6277 15.6277 15.8224 15.3827 15.9239C15.1989 16 14.9659 16 14.5 16C14.0341 16 13.8011 16 13.6173 15.9239C13.3723 15.8224 13.1776 15.6277 13.0761 15.3827C13 15.1989 13 14.9659 13 14.5V9.5Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                  />
                </svg>
              </div>
              <div v-else>
                <div
                  style="height: 20px; width: 20px"
                  class="flex items-center justify-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style="height: 12px; width: 12px"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path
                      d="M20.4086 9.35258C22.5305 10.5065 22.5305 13.4935 20.4086 14.6474L7.59662 21.6145C5.53435 22.736 3 21.2763 3 18.9671L3 5.0329C3 2.72368 5.53435 1.26402 7.59661 2.38548L20.4086 9.35258Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </el-tooltip>
          <el-tooltip
            content="Следующий фрагмент (Alt + →)"
            :show-after="300"
            :disabled="notAllowedNext"
          >
            <el-button
              :disabled="notAllowedNext"
              class="button"
              @click="nextFragment"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style="height: 20px; width: 20px"
                viewBox="0 0 24 24"
                fill="transparent"
              >
                <path
                  d="M9 5L15 12L9 19"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </el-button>
          </el-tooltip>
        </div>
      </div>
      <audio ref="audio" :src="`${audioSrc}`"></audio>
    </div>
  </div>
</template>

<script>
import apiClient from "@/api/api-client";
import WaveSurfer from "wavesurfer.js";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.esm.js";
import TimelinePlugin from "wavesurfer.js/dist/plugins/timeline.esm.js";
const regions = RegionsPlugin.create();
const regions2 = RegionsPlugin.create();

const audioContext = new (window.AudioContext || window.webkitAudioContext)();
let minPxPerSec = 1;

export default {
  props: {
    fileId: {
      type: String,
    },
    cue: {
      type: Number,
      default: 0,
    },
    lines: {
      type: Array,
      default: () => [],
    },
    notAllowedNext: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      waveSurfer: null,
      waveformFragment: null,
      canvas: null,
      context: null,
      channel: "",
      audioStart: 1,
      animationFrame: null,

      isPlaying: false,
      currentAudioSource: null,
      currentAudioTime: 0,

      speed: "1x",
      speeds: [0.5, 0.6, 0.75, 1, 1.25, 1.5, 1.75, 2],
      audio: [],
      mapLoading: false,
      fragmentLoading: false,
    };
  },
  computed: {
    currentFragment() {
      return this.lines[this.audioStart - 1]; // Get the current fragment (1-based index)
    },
    duration() {
      if (
        this.waveSurfer &&
        typeof this.waveSurfer.getDuration === "function"
      ) {
        const duration = this.waveSurfer.getDuration();
        // Ensure duration is a valid number greater than zero
        if (!isNaN(duration) && duration > 0) {
          return duration;
        }
      }
      return 0; // Return 0 if duration is invalid or WaveSurfer is not ready
    },
    audioURL() {
      return (
        (this.audio &&
          this.audio.data &&
          this.audio.data.data &&
          this.audio.data.data.meta &&
          this.audio.data.data.meta.url_hash) ||
        ""
      );
    },
    MEDIA_DOMAIN() {
      return "https://dev.tr.q19.kz";
    },
    audioSrc() {
      if (this.audioURL) {
        return `${this.MEDIA_DOMAIN}${this.audioURL}`;
      } else {
        return "";
      }
    },
  },
  watch: {
    audioStart(newStart) {
      const newFragment = this.lines[newStart - 1];
      if (newFragment) {
        this.pauseCurrentFragment();
        this.isPlaying = false;
        this.drawRegionFromMap(newFragment);
      }
    },
  },
  async mounted() {
    await this.getAudio();
    this.initializeWaveformFragment();
    this.initializeWaveSurfer();
    this.canvas = document.getElementById("waveformCanvas");
    this.context = this.canvas.getContext("2d");
    window.addEventListener("keydown", this.handleSpacebar);
    window.addEventListener("keydown", this.handleFragment);
  },
  beforeUnmount() {
    if (this.waveSurfer) {
      this.waveSurfer.destroy();
    }
    if (this.waveformFragment) {
      this.waveformFragment.destroy();
    }
    window.removeEventListener("keydown", this.handleFragment);
    window.removeEventListener("keydown", this.handleSpacebar);
  },

  methods: {
    operatorWave() {
      return this.currentFragment && this.currentFragment.channel === "op"
        ? 150
        : 0;
    },
    clientWave() {
      return this.currentFragment && this.currentFragment.channel === "user"
        ? 150
        : 0;
    },
    updateChannelHeights() {
      if (this.waveSurfer) {
        // Update the channel configurations
        this.waveSurfer.options.splitChannels = [
          {
            waveColor: "#0080ff",
            progressColor: "#0080ff",
            height: this.operatorWave(),
          },
          {
            waveColor: "#0080ff",
            progressColor: "#0080ff",
            height: this.clientWave(),
          },
        ];
      }
    },
    initializeWaveSurfer() {
      this.fragmentLoading = true;
      this.waveSurfer = WaveSurfer.create({
        container: "#waveform",
        barWidth: 3,
        barRadius: 2,
        cursorWidth: 2.5,
        interact: !this.isPlaying,
        audioContext,
        plugins: [regions2],
      });

      if (this.audioSrc) {
        this.waveSurfer.load(this.audioSrc);
      }

      // Optional: Handle play/pause controls
      this.waveSurfer.on("ready", () => {
        console.log("WaveSurfer is ready!", this.waveSurfer);
        this.fragmentLoading = false;

        const scrollWidth = this.waveSurfer.renderer.wrapper.scrollWidth;
        minPxPerSec = scrollWidth / this.waveSurfer.getDuration();

        this.waveSurfer.setOptions({
          minPxPerSec,
        });
        const initialFragment = this.currentFragment;
        if (initialFragment) {
          this.drawRegion(initialFragment);
        }
      });
      this.waveSurfer.on("click", (progress) => {
        // Calculate the clicked position as a time in the audio
        const clickedTime = progress * this.waveSurfer.getDuration();

        // Call the new method to play the fragment from the clicked position
        this.playCurrentFragmentClick(clickedTime);
      });
    },
    initializeWaveformFragment() {
      this.mapLoading = true;
      this.waveformFragment = WaveSurfer.create({
        container: "#waveformFragment",
        waveColor: "rgb(200, 0, 200)",
        cursorWidth: 0,
        barWidth: 3,
        barRadius: 2,
        audioContext,
        interact: false,
        height: 100,
        splitChannels: true,
        plugins: [regions, TimelinePlugin.create()],
      });

      if (this.audioSrc) {
        this.waveformFragment.load(this.audioSrc);
      }
      // Optional: Handle play/pause controls
      this.waveformFragment.on("ready", () => {
        this.mapLoading = false;
      });
    },
    async playChannelFragment(channelIndex, startTime, endTime) {
      // Ждем, пока аудио будет готово

      const audioBuffer = this.waveSurfer.getDecodedData();
      const channelData = audioBuffer.getChannelData(channelIndex);

      // Рассчитываем данные для указанного фрагмента
      const startSample = Math.floor(startTime * audioBuffer.sampleRate);
      const endSample = Math.floor(endTime * audioBuffer.sampleRate);
      const fragmentData = channelData.slice(startSample, endSample);

      // Создаем новый буфер с данными фрагмента
      const fragmentBuffer = audioContext.createBuffer(
        1, // Один канал
        fragmentData.length,
        audioBuffer.sampleRate
      );
      fragmentBuffer.copyToChannel(fragmentData, 0);

      // Воспроизводим буфер фрагмента
      const fragmentSource = audioContext.createBufferSource();
      fragmentSource.buffer = fragmentBuffer;
      fragmentSource.connect(audioContext.destination);
      fragmentSource.start();
      this.currentAudioSource = fragmentSource;
      this.isPlaying = true;

      // Handle the end of playback
      fragmentSource.onended = () => {
        this.isPlaying = false;
        this.currentAudioSource = null;
        cancelAnimationFrame(this.animationFrame);
      };
    },
    startUpdatingCursor(startTime, endTime) {
      const duration = endTime - startTime; // Duration of the current fragment
      const totalDuration = this.waveSurfer.getDuration(); // Total audio duration
      let startTimestamp = audioContext.currentTime; // Use `let` to allow resetting

      const updateCursor = () => {
        if (!this.isPlaying) {
          cancelAnimationFrame(this.animationFrame);
          this.animationFrame = null;
          return;
        }

        const elapsedTime = audioContext.currentTime - startTimestamp;
        let progress = elapsedTime / duration;

        if (progress >= 1) {
          // Reset progress and start timestamp for looping
          progress = 0;
          startTimestamp = audioContext.currentTime;
        }

        // Calculate the position relative to the entire audio file
        const fragmentStartRatio = startTime / totalDuration;
        const fragmentEndRatio = endTime / totalDuration;
        const currentPosition =
          fragmentStartRatio +
          progress * (fragmentEndRatio - fragmentStartRatio);

        this.waveSurfer.seekTo(currentPosition); // Update WaveSurfer cursor based on fragment

        // Continue updating
        this.animationFrame = requestAnimationFrame(updateCursor);
      };

      this.animationFrame = requestAnimationFrame(updateCursor); // Start updating
    },
    pauseCurrentFragment() {
      if (this.currentAudioSource) {
        this.currentAudioSource.stop(); // Stop current playback
        this.currentAudioTime = audioContext.currentTime; // Store current time for resuming
        this.isPlaying = false;
        cancelAnimationFrame(this.animationFrame); // Stop cursor updates
        this.animationFrame = null; // Reset animation reference
      }
    },
    stopCurrentAudio() {
      if (this.currentAudioSource) {
        this.currentAudioSource.stop(); // Stop current playback
        this.currentAudioSource.disconnect(); // Disconnect from audio context
        this.currentAudioSource = null; // Reset the audio source
      }

      this.isPlaying = false; // Update playback state
      this.currentAudioTime = 0; // Reset playback time
      cancelAnimationFrame(this.animationFrame); // Stop cursor updates
      this.animationFrame = null; // Reset animation reference
    },
    playCurrentFragmentClick(clickedTime) {
      // Find the fragment that corresponds to the clicked position
      const fragment = this.currentFragment;

      if (fragment) {
        // Update the audio start time and play from the clicked position to the end of the fragment
        const timeStart = clickedTime;
        const timeEnd = fragment.end;

        // If there is already a source playing, stop it
        if (this.currentAudioSource) {
          this.stopCurrentAudio();
        }

        // Play the fragment from the clicked position to its end
        this.playChannelFragment(
          fragment.channel === "user" ? 1 : 0,
          timeStart,
          timeEnd
        );

        const offset = Math.min(0.009, (timeEnd - timeStart) * 0.01); // 1% of the fragment length
        this.startUpdatingCursor(timeStart, timeEnd - offset);
      }
    },
    playCurrentFragment() {
      // Update the audio start time
      const currentFragment = this.currentFragment; // Adjust for zero-based index
      if (currentFragment) {
        const timeStart = currentFragment.start;
        const timeEnd = currentFragment.end;

        if (this.currentAudioSource) {
          this.stopCurrentAudio();
        }

        this.playChannelFragment(
          currentFragment.channel === "user" ? 1 : 0,
          timeStart,
          timeEnd
        );
        const offset = Math.min(0.009, (timeEnd - timeStart) * 0.01); // 1% of the fragment length
        this.startUpdatingCursor(timeStart, timeEnd - offset);
      }
    },
    drawRegion(currentFragment) {
      if (!currentFragment) {
        console.error("No fragment provided!");
        return;
      }

      // Clear existing regions
      regions2.clearRegions();

      const fragmentStart = currentFragment.start;
      const fragmentEnd = currentFragment.end;
      const fragmentDuration = fragmentEnd - fragmentStart;

      // Add new region
      regions2.addRegion({
        start: fragmentStart,
        end: fragmentEnd,
        drag: false, // Disable drag
        resize: false, // Disable resize
        color: "transparent",
      });
      this.updateChannelHeights();

      // Calculate zoom based on fragment duration and apply it
      const zoom =
        (this.waveSurfer.getDuration() / fragmentDuration) * minPxPerSec;

      this.waveSurfer.zoom(zoom);
      this.waveSurfer.setScrollTime(fragmentStart);
    },
    drawRegionFromMap(currentFragment) {
      if (!currentFragment) return; // Ensure the current fragment exists
      if (this.context) {
        // Clear the canvas before drawing the new region
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

        const audioDuration = this.duration; // Total audio duration in seconds
        const regionStart = currentFragment.start; // Region start in seconds
        const regionEnd = currentFragment.end; // Region end in seconds

        // Calculate the pixel-per-second scale
        const secondWidth = this.canvas.width / audioDuration;

        // Calculate the start X position and the width of the region in pixels
        const regionStartX = regionStart * secondWidth;
        const regionWidth = (regionEnd - regionStart) * secondWidth;

        const regionHeight = 75; // Full canvas height or any height

        // Set fill color for the region
        this.context.fillStyle = "rgba(128, 128, 128, 0.3)";

        const userPosition = currentFragment.channel === "op" ? 0 : 75;
        // Draw region as a rectangle
        this.context.fillRect(
          regionStartX,
          userPosition,
          regionWidth,
          regionHeight
        );
      }
    },
    handleFragment(event) {
      // Check if the Alt key (Windows) or Option key (Mac) is pressed
      if ((event.altKey || event.metaKey) && event.key === "ArrowLeft") {
        event.preventDefault();
        this.previousFragment(); // Call previous fragment method
      } else if (
        (event.altKey || event.metaKey) &&
        event.key === "ArrowRight" &&
        !this.notAllowedNext
      ) {
        event.preventDefault();
        this.nextFragment(); // Call next fragment method
      }
    },
    previousFragment() {
      // Update the fragment index
      if (this.audioStart > 1) {
        this.audioStart -= 1;
        this.$emit("previous-fragment", this.audioStart);
        this.drawRegion(this.currentFragment);
      }
    },
    nextFragment() {
      if (this.audioStart < this.lines.length) {
        this.audioStart += 1;
        this.$emit("next-fragment", this.audioStart);
        this.drawRegion(this.currentFragment);
      }
    },
    async getAudio() {
      this.audio = (await apiClient.get(`file/${this.fileId}`)) || [];
    },
    timeNormalizer(timeInSeconds) {
      var pad = function (num, size) {
          return ("000" + num).slice(size * -1);
        },
        time = parseFloat(timeInSeconds).toFixed(3), // Keep 3 decimals without rounding
        hours = Math.floor(time / 60 / 60),
        minutes = Math.floor(time / 60) % 60,
        seconds = Math.floor(time - minutes * 60),
        milliseconds = (timeInSeconds % 1).toFixed(3).slice(2); // Extract 3 decimals of milliseconds

      // Remove trailing zeroes if they exist
      milliseconds = parseFloat("0." + milliseconds)
        .toFixed(2)
        .slice(2);

      if (timeInSeconds >= 3600) {
        return (
          pad(hours, 2) +
          ":" +
          pad(minutes, 2) +
          ":" +
          pad(seconds, 2) +
          "," +
          milliseconds
        );
      }
      return pad(minutes, 2) + ":" + pad(seconds, 2) + "," + milliseconds;
    },

    playPause() {
      if (this.isPlaying) {
        this.pauseCurrentFragment(); // Pause if currently playing
      } else {
        this.playCurrentFragment(); // Play if currently paused
      }
    },
    handleSpacebar(event) {
      if (event.code === "Escape") {
        event.preventDefault();
        this.playPause(); // Run your play/pause logic here
      }
    },
  },
};
</script>

<style scoped lang="scss">
#waveformFragment {
  cursor: default !important;
}
#waveform ::part(scroll) {
  overflow: hidden !important;
}
#waveformFragment ::part(region) {
  border-radius: 0 !important;
}
.button {
  border: 1px solid transparent;
  background-color: #888d93;
  color: #fff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.13);
  min-width: 30px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 0 15px;
  cursor: pointer;
}
</style>
