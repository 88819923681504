<script>
import { getBatchesList } from "@/api/batches";
import { exportBatchFile } from "@/api/batches";

export default {
  name: "FileUnloadSettings",
  data() {
    return {
      isVisible: true,
      batchesList: [],
      loading: false,
      selectedBatch: null,
      selectedBatchName: "",
    };
  },
  computed: {
    domain() {
      return "https://dev.tr.q19.kz";
    },
  },
  created() {
    this.getBatches();
  },
  methods: {
    async unloadBatchFile() {
      this.loading = true;
      try {
        const data = await exportBatchFile(this.selectedBatch);
        if (data && data.output_path) {
          const url = data.output_path;
          const fileUrl = `${this.domain}${url}`;
          this.downloadFile(fileUrl);
        } else {
          alert("Не успешно");
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    downloadFile(fileUrl) {
      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.href = url;
          a.download = `${this.selectedBatchName}_json_out.json`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading the file:", error);
        });
    },
    async getBatches() {
      this.loading = true;
      try {
        const { data } = await getBatchesList();
        this.batchesList = data || [];
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false; // Stop loading
      }
    },
    close() {
      this.$router.push({ name: "settings" });
    },
    handleBatchChange(value) {
      const selected = this.batchesList.find((batch) => batch._id === value);
      this.selectedBatchName = selected ? selected.name : "";
    },
  },
};
</script>

<template>
  <el-drawer
    size="600"
    title="Выгрузить файл"
    v-model="isVisible"
    @close="close"
  >
    <div v-loading="loading">
      <span class="text-sm text-gray-800">Выберите batch для выгрузки</span>
      <div class="flex items-center gap-x-3 mt-3">
        <el-select
          v-model="selectedBatch"
          size="large"
          placeholder="Выбрать"
          class=""
          clearable
          @change="handleBatchChange"
        >
          <el-option
            v-for="batch in batchesList"
            :key="batch._id"
            :label="batch.batch_name"
            :value="batch._id"
          ></el-option>
        </el-select>
        <el-button
          @click="unloadBatchFile"
          :disabled="!selectedBatch"
          size="large"
          type="primary"
          plain
          >Выгрузить</el-button
        >
      </div>
    </div>
  </el-drawer>
</template>

<style scoped lang="scss"></style>
